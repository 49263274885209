<template>

  <van-popup
      v-model="openFilter"
      closeable

      close-icon="close"
      position="bottom"
      round
      :style="{ height: px2rem(332) }"
  >
    <h1>审核驳回</h1>

    <div class="filter-item">
      <label class="required">驳回原因</label>
      <div class="select" @click="showReason = true">
        <div class="placehoder" v-if="!form.reason">
          请选择
        </div>
        <div class="value">
          {{ form.reason }}
        </div>

        <img src="./../../../assets/images/integral/arrow.png" alt="">
      </div>
    </div>
    <div class="filter-item">
      <label>备注说明</label>
      <div class="input">
        <textarea v-model="form.remark"/>
      </div>
    </div>

    <div class="buttonGroup">
      <van-row gutter="14">
        <van-col :span="12">
          <van-button block type="info" color="#F2F2F2" round @click="onCancel">
            <span style="color: #333;">取消</span>
          </van-button>
        </van-col>
        <van-col :span="12">
          <van-button block type="primary" color="#D53A26" round @click="onSubmit">提交</van-button>
        </van-col>
      </van-row>
    </div>


    <van-action-sheet v-model="showReason" title="退款原因" close-icon="close">
      <van-radio-group v-model="reason_stack">
        <van-cell-group>
          <van-cell v-for="item in reasonList" :title="item.text" clickable @click="reason_stack = item.value">
            <template #right-icon>
              <van-radio :name="item.value" checked-color="#FF553E"/>
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>

      <div style="padding: 10px 18px 18px 23px;">
        <van-button color="#D53A26" type="primary" size="small" block round @click="onReasonConfirm">确定</van-button>
      </div>
    </van-action-sheet>
  </van-popup>
</template>

<script>
export default {
  name: "RejectPopup",
  data() {
    return {
      showReason: false,
      reason_stack: '',
      openFilter: false,
      form: {
        reasonId: '',
        reason: '',
        remark: ''
      },
      product: {},
      reasonList: [{
        text: '图片弄错了',
        value: 1
      }, {
        text: '价格弄错了',
        value: 2
      }, {
        text: '产品成分写错了或没写',
        value: 3
      }, {
        text: '商品名称弄错了',
        value: 4
      }, {
        text: '销售开始或结束时间搞错了',
        value: 5
      }]
    }
  },

  methods: {
    onReasonConfirm() {
      this.form.reasonId = this.reason_stack;
      this.form.reason = this.getReasonText(this.form.reasonId)
      this.showReason = false
    },
    getReasonText(val) {
      if (val === '') {
        return {text: ''}
      }
      return this.reasonList.find(e => e.value == val).text;
    },
    showPop(data) {
      this.product = data.product;
      this.openFilter = true
    },

    onCancel() {
      this.openFilter = false
    },
    onSubmit() {
      if (!this.form.reason) {
        this.$toast('请选择原因');
        return;
      }
      this.openFilter = false;
      this.$http.post(this.baseProductUrl + `/h5_product/saveRejectProductLog`, {
        productId: this.product.id,
        reason: this.form.reason + "\n" + this.form.remark
      }, {emulateJSON: true}).then(() => {
        this.$toast.success('驳回成功')
        this.$router.replace('/mine')
      })
    }
  }
}

</script>
<style lang="less" scoped>

h1 {
  line-height: 1;
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #212121;
  margin-top: 24px;
  text-align: center;
}


.filter-item {
  margin-top: 27px;
  padding: 0 18px;

  label {
    font-size: 15px;
    font-weight: 500;
    color: #212121;
    margin-bottom: 14px;
    display: block;
    position: relative;

    &.required {
      padding-left: 8px;

      &::after {
        content: "*";
        position: absolute;
        color: #f00;
        font-size: 15px;
        left: 0;
        top: 0;
      }
    }
  }

  .select {
    width: 100%;

    height: 32px;
    background: #EEEEEE;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 22px;

    img {
      width: 8px;
      height: 5px;
    }

    > div {
      font-size: 12px;
      font-weight: 400;

      &.placehoder {
        color: #999999;
      }

      &.value {
        color: #333;
      }
    }
  }

  .input textarea {
    background: #EEEEEE;
    border-radius: 4px;
    height: 50px;
    border: none;
    resize: none;
    width: 100%;

    font-size: 12px;
    font-weight: 400;
    color: #333;
    padding: 7px 18px;
  }
}

.buttonGroup {
  padding: 20px 18px 0;
}
</style>
