<template>
  <div>
    <van-action-sheet v-model="show" @closed="showComponent = false">
      <img class="skuClose" @click="show = false" src="@/assets/images/goods/goodsDetail/skuClose.png" alt="">
      <div class="content">
        <div class="goodsInfo">
          <img :src="product.imgs[0]" alt="">
          <div class="info">
            <div class="price">
              <span>¥</span>
              <span>{{ product.price }}</span>
            </div>
            <div class="name">
              {{product.name}}
            </div>
          </div>
        </div>

        <!-- 选择规格方式 -->

        <div class="skuBody">
          <sku-custom v-if="showComponent"  :product="product" @onSubmit="onSubmit"/>
        </div>

      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import skuCustom from './skuCustom'

export default {
  components: {skuCustom},
  name: "Sku",
  data() {
    return {
      showComponent: false,
      show: false,
      callback: null,
      product:{
        imgs:[],
      },
    }
  },
  props: {
    id:{default:"",type:String}
  },
  watch:{
    id:{
      handler(val){
        if(''!==val){
          this.product={ imgs:[]}
          this.id = val
          this.queryProduct();
        }
      },
      immediate:true,
    }
  },
  methods: {
    queryProduct(){
      this.$http.get(`/product/h5_product/queryProductInfo/${this.id}`).then(res => {
        let product = res.data.product;
        product.imgs = product.image.split(',');
        this.product = product;
      });
    },
    showPop(callback) {
      this.showComponent = true
      this.show = true
      if (callback) {
        this.callback = callback
      } else {
        this.callback = null
      }
    },
    onSubmit(data) {
      console.log(data.type) // JOINCART / BUY / CONFIRM
      // 所有的数据判断 处理都在组件内完成了， 这里不做任何处理， 直接上发
      // 如果有传callback进来 ， 就会把确认事件提交给外部页面，
      /** 比如这种就是打开的时候传入了回调
       this.$refs.sku.showPop((data) => {
                    // TODO
                })
       */
      if (this.callback) {
        this.callback(data)
        this.show = false
        return;
      }

      if (data.type === 'CONFIRM') {
        // TODO
      } else if (data.type === 'REJECT') {
        this.$emit('onReject', data)
      }

      this.show = false
      // TODO SERVER
      // 否则就发起请求
    }
  },
  mounted() {
    /**
     * /goodsDetail/123
     * <goods-detail :id="123" />
     * 两种方式都使用this.id 获取商品id
     */
    this.queryProduct();
    console.log(this.id);  // 商品ID， 不管是 /goodsDetail/123 还是通过组件， 都会到这
  },
}
</script>
<style lang="less" scoped>
.skuClose {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 26px;
  right: 19px;
  z-index: 11;
}

.content {
  padding: 0 18px;
  transition: all .2s;
  position: relative;

  .goodsInfo {

    display: flex;
    align-items: flex-end;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 24px 0 10px 0;
    z-index: 10;

    img {
      width: 100px;
      height: 84px;
      border-radius: 5px;
      margin-right: 15px;
    }

    .price {
      height: 15px;

      span {
        font-weight: bold;
        color: #D53A26;

        &:first-child {
          font-size: 12px;
          margin-right: 3px;
        }

        &:last-child {
          font-size: 18px;
        }
      }
    }

    .name {
      margin-top: 12px;
      font-size: 10px;
      font-weight: 400;
      color: #333333;
      line-height: 15px;
    }
  }


}

.start {
  height: 25px;
  background: #F3E9FE;
  border-radius: 4px;
  line-height: 25px;
  padding: 0 10px;

  font-size: 10px;
  font-weight: 400;
  color: #C226D5;
  line-height: 25px;

}


.rule {
  width: 100%;
  margin-bottom: 10px;
}
</style>
