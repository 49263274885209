<template>
  <div class="page-gray">
    <goods-detail :id="productId" :hiddenToolbar="true"/>
    <div class="button">
      <van-button round block type="primary" @click="toAudit" color="#D53A26">审核</van-button>
    </div>

    <inventory :id="productId" ref="inventory"/>
    <reject-popup ref="reject"/>
  </div>
</template>

<script>
import goodsDetail from '../goods/goodsDetail.vue'
import inventory from './detailComponent/inventory.vue'
import RejectPopup from './detailComponent/rejectPopup.vue'

export default {
  components: {goodsDetail, inventory, RejectPopup},
  name: "AuditDetail",
  data(){
    return {
      productId:"",
    }
  },
  created() {
    this.productId = this.$route.query.id;
  },
  activated() {
    this.productId = this.$route.query.id;
  },
  methods: {
    toAudit() {
      this.$refs.inventory.showPop((data) => {
        if (data.type === 'REJECT') {
          this.$refs.reject.showPop(data.data)
        } else {
          this.$http.post(this.baseProductUrl + `/h5_product/saveAdoptProductLog`, {
            productId: data.data.product.id,
          }, {emulateJSON: true}).then(() => {
            this.$toast.success('审核通过')
            this.$router.replace('/audit')
          })
        }

      })
    }
  }
}

</script>
<style lang="less" scoped>
.page-gray {
  background-color: #fff;
}

.button {
  padding: 8px 18px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100vw;
}
</style>
